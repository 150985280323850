import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  modalHeading: {
    paddingRight: theme.spacing(4),
    ...(theme.components?.modal?.heading
      ? theme.components?.modal?.heading
      : {})
  }
}));

export default function ModalHeading({ children }) {
  const classes = useStyles();

  return (
    <Box className={classes.modalHeading} pt={0.9} pb={2}>
      {children}
    </Box>
  );
}

ModalHeading.propTypes = {
  children: PropTypes.node.isRequired
};

ModalHeading.defaultProps = {};
